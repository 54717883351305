
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import { IManageChangeSiteAddressRequestParams } from "@/interfaces/manageChangeSiteAddressRequestParams";
import { IManageSiteAddressPrepopulated } from "@/interfaces/manageSiteAddressPrepopulated";

import moment from 'moment';

const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");
const Manage = namespace("Manage");

@Component({
    components: {
        WizardBaseStep
    }
})


export default class WizardStepSiteAddress extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

    private StartDate = '';
    private EndDate = '';

    private processing = false;

    private intervalHandle: (number | null) = null;
    private estimatedMaxDownloadSeconds = 40;
    private downloadProgressCounter = 0;

    private contactName = '';
    private emailAddress = '';
    private siteName = '';
    private addressLineOne = '';
    private addressLineTwo = '';
    private addressLineThree = '';
    private addressLineFour = '';
    private town = '';
    private postcode = '';
    private telephone = '';

    private showEmailSentMessage = false;

    private watchGasAgreementSelection: any;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @GasAccounts.Getter
    private getSelectedSiteRefNumber!: string;

    @Manage.Getter
    private getEmailSentStatus!: boolean;

    @Manage.Getter
    private getPrepopulatedSiteAddress!: IManageSiteAddressPrepopulated;

    @Manage.Action
    private sendSiteAddressDetails!: (params: IManageChangeSiteAddressRequestParams) => Promise<void>;

    @Manage.Action
    private getSiteAddressPrepopulated!: (params: { userId: string, accountNumber: string, siteRef: string }) => Promise<void>;

    @Auth.Getter
    private getUserEmail!: string;

    @Auth.Getter
    private isInImpersonationMode!: boolean;

    onFormSubmit(): void {
        this.$validator.validateAll().then((isValid) => {
            if (!isValid) {
                return;
            }

            this.manageChangeSiteDetailsRequestParams.ContactName = this.contactName;
            this.manageChangeSiteDetailsRequestParams.Email = this.emailAddress;
            this.manageChangeSiteDetailsRequestParams.SiteName = this.siteName;
            this.manageChangeSiteDetailsRequestParams.Address1 = this.addressLineOne;
            this.manageChangeSiteDetailsRequestParams.Address2 = this.addressLineTwo;
            this.manageChangeSiteDetailsRequestParams.Address3 = this.addressLineThree;
            this.manageChangeSiteDetailsRequestParams.Address4 = this.addressLineFour;
            this.manageChangeSiteDetailsRequestParams.PostCode = this.postcode;
            this.manageChangeSiteDetailsRequestParams.Telephone = this.telephone;
            this.manageChangeSiteDetailsRequestParams.SelectedSiteRef = this.getSelectedSiteRefNumber;
            this.manageChangeSiteDetailsRequestParams.SelectedUser = this.getUserEmail;
            this.manageChangeSiteDetailsRequestParams.SelectedAccount = this.getSelectedAccount;
            this.manageChangeSiteDetailsRequestParams.UserId = this.getUserId;

            if (this.manageChangeSiteDetailsRequestParams != null && this.manageChangeSiteDetailsRequestParams.ContactName != null) {
                this.sendSiteAddressDetails(this.manageChangeSiteDetailsRequestParams).then(() => {
                    this.showEmailSentMessage = this.getEmailSentStatus;
                });
            }
        });

    }

    private manageChangeSiteDetailsRequestParams: IManageChangeSiteAddressRequestParams =
    {
        ContactName: null,
        Email: null,
        SiteName: null,
        Address1: null,
        Address2: null,
        Address3: null,
        Address4: null,
        PostCode: null,
        Telephone: null,
        SelectedSiteRef: null,
        SelectedUser: null,
        SelectedAccount: null,
        UserId: null
    }

    beforeMount() {
        const dict = {
            custom: {
                contactName: {
                    required: 'Contact name is required'
                },
                emailAddress: {
                    required: 'Email address is required',
                    email: 'Please enter a full valid email address.'
                },
                siteName: {
                    required: 'Site name is required'
                },
                addressLineOne: {
                    required: 'Address line 1 is required'
                },
                postcode: {
                    required: 'Postcode is required'
                },
                telephone: {
                    required: 'Telephone is required'
                }

            }
        };

        this.$validator.localize('en', dict);


    }

    mounted(): void {

        this.watchGasAgreementSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedSiteRefNumber"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }
            this.setPrepopulatedSiteAddress();
        });

        this.setPrepopulatedSiteAddress();
    }

    private setPrepopulatedSiteAddress() {
        this.getSiteAddressPrepopulated({ userId: this.getUserId, accountNumber: this.getSelectedAccount, siteRef: this.getSelectedSiteRefNumber }).then(() => {
            this.contactName = '';
            this.emailAddress = '';
            this.siteName = this.getPrepopulatedSiteAddress.SiteName;
            this.addressLineOne = this.getPrepopulatedSiteAddress.Address1;
            this.addressLineTwo = this.getPrepopulatedSiteAddress.Address2;
            this.addressLineThree = this.getPrepopulatedSiteAddress.Address3;
            this.addressLineFour = this.getPrepopulatedSiteAddress.Address4;
            this.town = '';
            this.postcode = this.getPrepopulatedSiteAddress.PostCode;
            this.telephone = '';
        });
    }

    beforeDestroy() {
        this.watchGasAgreementSelection();
    }
}
