











































import { Component, Watch, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import BaseWizard from "@/components/wizard/BaseWizard.vue"
import WizardStepManageAccountOptions from "@/components/wizard/manageaccount/Gas/WizardStepManageAccountOptions.vue";
import WizardStepEmergencyDetails from "@/components/wizard/manageaccount/Gas/WizardStepEmergencyDetails.vue";
import WizardStepBillingDetails from "@/components/wizard/manageaccount/Gas/WizardStepBillingDetails.vue";
import WizardStepSiteAddress from "@/components/wizard/manageaccount/Gas/WizardStepSiteAddress.vue";


import { JourneyNode } from '@/utilities/JourneyNode';
import { namespace } from 'vuex-class';

const Products = namespace("Products");
const InvoicingWizard = namespace("InvoicingWizard");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        BaseWizard,
        BasePage,
        WizardStepManageAccountOptions,
        WizardStepEmergencyDetails,
        WizardStepBillingDetails,
        WizardStepSiteAddress
    },
})
export default class ManageAccount extends Vue {
    private isExactActive = false;
    private watchProductSelection: any;
    private watchGasAccountSelection: any;
    private componentKey = 0;
    private transitionName = 'fade';
    private onSingleAccountAndOnSingleSite = false;
    private moreThanOneAccount = true;


    private next_step_gas_id = ["step-1", "step-4", "step-5"];

    @Watch('$route.path', { immediate: true, deep: true })
    onUrlChange(to: string, from: string) {
        if (to == null || to == undefined) {
            this.transitionName = 'fade';
            return;
        }
        const toDepth = to.split('/').length
        const fromDepth = from?.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    @InvoicingWizard.Getter
    private getRootJourney!: JourneyNode | null;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @InvoicingWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    @InvoicingWizard.Action
    private appendJourneyToJourneyNode!: (option: number) => Promise<void>;

    @Products.Getter
    private getSelectedProduct!: string;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    public async onSelectedInvoicingOption(option: number) {
        await this.initJourneyNode(option);
    }

    public async onSelectedLatestInvoicesOption(option: number) {
        await this.appendJourneyToJourneyNode(option);
    }

    public findJourney(option: number): boolean {
        let root = this.getRootJourney;
        if (root !== null) {
            while (root !== null) {
                if ((root as JourneyNode).Value == option) {
                    return true;
                }
                root = (root as JourneyNode).Next;
            }
        }
        return false;
    }

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
        this.resetJourneyNode();

        try {
    
            this.watchGasAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.forceRerender();
                this.resetJourneyNode();
            });

        } catch (e) {

            console.error(e);
        }

        this.onSingleAccountAndOnSingleSite = this.isSass;
        this.moreThanOneAccount = this.getUserAccountsCount > 1;
    }

    forceRerender() {
        this.componentKey += 1
    }

    beforeDestroy() {
        this.watchGasAccountSelection();
    }
}
